.root {
  position: fixed;
  padding: 0 !important;
  top: 0px;
  left: 0px;
  width: 100%;
  background: rgb(34, 36, 42, 0.25) !important;
  height: var(--nav-bar-height) !important;
  backdrop-filter: blur(12px);
  z-index: 10;
}

.content {
  padding: 0px 16;
  line-height: normal;
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  margin-right: 38px;
}

.menu {
  border-bottom: none !important;
  background: none !important;
  flex: 1 1 0;
}

.menu li {
  padding: 8px 16px !important;
}

.menu li::after {
  border: none !important;
}

.buttons {
  margin-right: 24px;
}

.rightMenu {
  height: 100%;
}

.rightMenu :global .ant-space,
.rightMenu :global .ant-space-item,
.rightMenu :global .ant-dropdown-trigger {
  height: 100%;
  display: flex;
  align-items: center;
}

.accountButton :global button {
  width: 40px;
  height: 40px;
  padding: 0px;
  border-width: 2px;
}

.downOutline {
  color: var(--orange-main) !important;
  margin-left: 8px;
}

.accountDropdown li {
  min-width: 212px;
  text-align: right;
}

.textLogo {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 6px;
}
