.root {
  border-radius: 999px !important;
  box-shadow: 0px 0px 10px #00000026 !important;
  padding: 12px 36px !important;
  height: 40px !important;
  line-height: 1 !important;
}

.default {
  border-width: 2px !important;
  color: var(--orange-main) !important;
}

.primary {
  border: none !important;
  background: var(--orange-main) !important;
  color: var(--orange-dark) !important;
}

.secondary {
  border: none !important;
  background: var(--accent-turqoise) !important;
  color: #ffffff;
}

.info {
  border: none !important;
  background: var(--accent-blue) !important;
  color: #ffffff !important;
}

.turqoise {
  border: none !important;
  background: #ffffff !important;
  color: var(--accent-turqoise) !important;
}

.large {
  height: 58px !important;
}

.large span {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.small {
  height: 20px !important;
  padding: 2px 22px 3px 22px !important;
}

.small span {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.root[ant-click-animating-without-extra-node="true"]::after {
  display: none;
}
