.dropdown {
  padding: 12px 16px;
}
.overlay ul {
  padding: 16px 0;
  background-color: rgb(34, 36, 42, 0.25);
}
.overlay :global li.ant-dropdown-menu-item {
  padding: 4px 16px;
  flex-direction: row-reverse;
}

.overlay span {
  margin-right: 16px;
}
